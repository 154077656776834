
<template>
  <div :id="`DATAVIEW_FORM`" style="height: 100%, width: 100%">
    <b-modal size="md" :title="labelTitle" :visible="show" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow"
      @ok="modalOk"
      @hidden="modalCancel"
      id="dataview-modal"
    >
      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <b-alert variant="danger" dismissible v-model="errorShow" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }} 
      </b-alert>
      
      <div v-if="dataviewOptions.length !== 0">
        <div class="mt-2 mb-2 d-block">
          {{ $t('dataview.field.name') }}
        </div>
        <b-form-input
          v-model="dataviewEditName"
        />
        <div class="mt-3 mb-2 d-block">
          {{ $t('dataview.select.component') }}
        </div>
        <b-form-select v-model="dataviewComponent" :options="dataviewOptions"/>
      </div>
      <div class="loading" v-else>
        <b-spinner variant="primary" label="Loading..."></b-spinner>
      </div>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="modalOk">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash';
import dSettings from "@/_dashboardSettings";
import { companyService } from '@/services';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import Vue from 'vue';

export default {
  name: 'DashboardLaunchpadModal',
  props: {
    title:     { type: String,   default: null },
    readOnly:  { type: Boolean,  default: false },
    widget:    { type: Object,   default: function() { return null }}
  },
  data() {
    return {
      isNew: true,
      show: false,
      modelInfo: null,
      alertMsg: null,
      state: {
        editable:            false,
        isSubmitting:        false,
      },
      dataviewComponent: null,
      dataviewOptions: [],
      dataviewEditName: null,
    }
  },
  created() {
    this.dataviewComponent = this.widget.dataview.id;
    this.dataviewEditName = this.widget.name;
    this.isNew = !this.widget.uuId
    this.prepare();
    this.fieldValidateUtil = fieldValidateUtil;
    this.originLaunchpad = null;
    this.show = true;
  },
  beforeDestroy() {
    this.show = false;
  },
  computed: {
    isReadOnly() {
      return !this.state.editable && this.readOnly || this.$store.state.epoch.value !== null;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'dataview.name');
    },
    errorShow() {
      return this.alertMsg != null;
    },
    labelTitle() {
      return this.isNew ? this.$t('dashboard.dataview.title_new') : this.$t('dashboard.dataview.title_edit');
    }
  },
  methods: {
    isEntity(entity, value) {
      if (entity !== null) {
        const entitylist = typeof entity !== 'undefined' ? entity.split('.') : [];
        if (entitylist.length === 0) {
          return false;
        }
        
        const entityValue = entitylist[entitylist.length - 1];
        if (entityValue === value) {
          return true;    
        }

      }
      return false;
    },
    async loadDataviewDetails(selected) {
      var details = null;
      
      // load the dataview
      const userId = this.$store.state.authentication.user.uuId;
      const profiles = await this.$store.dispatch('data/dataViewProfileList', userId).then((response) => {
        const profileData = response;
        const privateProfiles = profileData.filter(f => f.sharedVisibility !== 'public').sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
        return privateProfiles;
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
        return [];
      });
      
      const privateDataview = profiles.filter(p => p.uuId === selected.dataview.uuId);
      if (privateDataview.length === 0) {
        // Get the public data views
        if (!localStorage.companyId) {
          const companyUuid = await companyService.list({ limit: -1, start: 0 }).then((response) => {
            const data = response.data;
            const company = data.filter(d => d.type === 'Primary');
            if (company.length > 0) {
              return company[0].uuId;
            }
          })
          .catch((e) => {
            console.log(e); // eslint-disable-line no-console
            return null;
          });
          
          localStorage.companyId = companyUuid;
          const pubProfiles = await this.$store.dispatch('data/dataViewProfileListPublic', companyUuid).then((response) => {
            const profileData = response.sort(function (a, b) {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
            return profileData;
          })
          .catch((e) => {
            console.log(e); // eslint-disable-line no-console
            return [];
          });
          const publicDataview = pubProfiles.filter(p => p.uuId === selected.dataview.uuId);
          if (publicDataview.length !== 0) {
            details = publicDataview[0];
          }
        }
        else {
          const pubProfiles = await this.$store.dispatch('data/dataViewProfileListPublic', localStorage.companyId).then((response) => {
            const profileData = response.sort(function (a, b) {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
            return profileData;
          })
          .catch((e) => {
            localStorage.removeItem('companyId');
            console.log(e); // eslint-disable-line no-console
            return [];
          });
          const publicDataview = pubProfiles.filter(p => p.uuId === selected.dataview.uuId);
          if (publicDataview.length !== 0) {
            details = publicDataview[0];
          }
        }
      }
      else {
        details = privateDataview[0];
      }
      return details;
    },
    async prepare() {
      this.dataviewOptions = [];
      const details = await this.loadDataviewDetails(this.widget);
      this.dataviewOptions.push({ text: this.$t('dataview.sheet'), value: 'sheet' });
      if (details && (details.showGantt || typeof details.showGantt === 'undefined') && this.isEntity(details.entity, 'TASK')) {
        this.dataviewOptions.push({ text: this.$t('dataview.gantt'), value: 'gantt' });
      }
      if (details && (details.showStaff || typeof details.showStaff === 'undefined') && (this.isEntity(details.entity, 'TASK') || 
          this.isEntity(details.entity, 'STAFF') || 
          this.isEntity(details.entity, 'DEPARTMENT') || 
          this.isEntity(details.entity, 'LOCATION') || 
          this.isEntity(details.entity, 'COMPANY') || 
          this.isEntity(details.entity, 'SKILL'))) {
        this.dataviewOptions.push({ text: this.$t('dataview.staff_usage'), value: 'staff_usage' });
      }
      if (details && (details.showBoard || typeof details.showBoard === 'undefined') && this.isEntity(details.entity, 'TASK')) {
        this.dataviewOptions.push({ text: this.$t('dataview.board'), value: 'board' });
      }
      if (details !== null && details.charts !== null) {
        for (const chart of details.charts) {
          this.dataviewOptions.push({ text: chart.name, value: chart.id });
        }
      }
      
      this.dataviewComponent = this.widget.dataview.id ? this.widget.dataview.id : 'sheet';
    },
    modalOk() {
      const editedWidget = cloneDeep(this.widget);
      editedWidget.name = this.dataviewEditName;
      editedWidget.dataview.id = this.dataviewComponent;
      editedWidget.dataview.componentName = this.dataviewOptions.filter(d => d.value === this.dataviewComponent)[0].text;
      this.$emit('ok', editedWidget);
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    modalCancel() {
      this.$emit('cancel');
    },
  }
}
</script>

<style lang="scss">
#dataview-modal___BV_modal_body_ .avatar-banner {
  background-size: contain;
  background-repeat: no-repeat;
}
</style>